import Sidebar from '../components/Sidebar';
import dynamic from 'next/dynamic';
import { useContext, useEffect, useState } from 'react';
import { store } from '../stores/store';
import Compare from '../components/Compare';
import { FetchEntry } from '../endpoints';
import Completed from '../components/Completed';
import NavBar from '../components/NavBar';
import EntryInPendingState from '../components/EntryInPendingState';
import qs from 'qs';
import { useRouter } from 'next/router';
import { cleanupOldCache } from '../stores/handle-dirty-states';
import { Block, Button, PremiseLogo, styled, StyledSpinnerNext, tokens, useStyletron } from '@premisedata/portal-design-system';
import DiffWarning from '../components/DiffWarning';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { firebaseConfig, useFirebaseAuth } from '../providers/firebaseContext';

const ImageAuditList = dynamic(() => import('../components/ImageAuditList'), {
  ssr: false
});

const ErrorDisplay = styled('div', ({ $theme }) => ({
  color: $theme.colors['brand-primary-125'],
  padding: $theme.sizing['x-large'],
  textAlign: 'center',
  fontSize: tokens['font-size']['x-large']
}));

const Container = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors['neutral-10'],
  display: 'flex',
  flexDirection: 'column',
  height: '100vh'
}));

const Content = styled('div', {
  display: 'flex',
  flex: 1,
  overflowY: 'auto',
  alignItems: 'stretch'
});

const EntryReview = () => {
  const { state, dispatch } = useContext(store);
  const { auth, user, setUser } = useFirebaseAuth();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const query = qs.parse(router.asPath.split('?')[1]);
  const auditIds = query.auditIds ? (JSON.parse(query.auditIds as string) as number[] | undefined) : undefined;
  const [, theme] = useStyletron();

  if (query.focusId) {
    const scrollToElement = () => {
      const el = document.querySelector(`#${query.focusId}`);
      if (el) {
        el.scrollIntoView();
      }
    };
    setTimeout(scrollToElement, 2000);
  }

  useEffect(() => {
    if (user) {
      state.errorHandler.start({
        key: firebaseConfig.apiKey || '',
        projectId: firebaseConfig.projectId || '',
        context: {
          user: user.email
        },
        service: 'Label Maker'
      });

      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    if (query.id) {
      setLoading(true);
      await cleanupOldCache();
      dispatch({ type: 'FETCH_ENTRY', query });
      try {
        const token = await user?.getIdToken();

        //unable to grab token, logout.
        if (!token) {
          setErrorMsg('Unable to authenticate, please sign out and try again.');
          return;
        }

        const data = await FetchEntry(state, query, token);
        dispatch({ type: 'ENTRY_FETCHED', payload: data, query });
      } catch (e: any) {
        if (e.message === 'Failed to fetch') {
          setErrorMsg('Your network timed out when trying to get entry details. Please check your internet connection or connect support if this continues to occur.');
        } else {
          await state.errorHandler.report(new Error(`Error while fetching entry: ${JSON.stringify(e)}`));
          setErrorMsg(e.message);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMsg('Proper link not followed, please use a valid link from Label Manager.');
    }
  };

  const handleLogin = () => {
    if (auth) {
      signInWithPopup(auth, new GoogleAuthProvider())
        .then((result) => {
          setUser(result.user);
        })
        .catch((error) => {
          setErrorMsg(error.message);
        });
    }
  };

  const handleSignout = () => {
    auth?.signOut();
  };

  if (!user) {
    return (
      <Block display="flex" justifyContent="space-between" padding={theme.sizing.small}>
        <PremiseLogo active />
        <Button onClick={handleLogin}>Login</Button>
        <div />
      </Block>
    );
  }

  if (state.saveCompleted) return <Completed />;

  if (state.extraction_in_progress) return <EntryInPendingState id={parseInt(query.id as string)} />;

  return (
    <Container>
      <NavBar hasError={!!errorMsg} hideLabels={query.showLabels === 'false'} hideBoundingBoxes={query.showBoundingBoxes === 'false'} onSignout={handleSignout} />
      {loading ? (
        <Block display="flex" justifyContent="space-around" marginTop={theme.sizing.large}>
          <StyledSpinnerNext />
        </Block>
      ) : (
        <>
          {query.id && <DiffWarning id={String(query.id)} query_category_id={Number(query.category_id)} />}
          {errorMsg ? (
            <ErrorDisplay>{errorMsg}</ErrorDisplay>
          ) : state.mode === 'tag' ? (
            <Content>
              {state.content && <ImageAuditList hideLabels={query.showLabels === 'false'} hideBoundingBoxes={query.showBoundingBoxes === 'false'} auditIds={auditIds} />}
              <Sidebar />
            </Content>
          ) : (
            <Content style={{ marginLeft: tokens.spacing.small }}>
              <Compare />
            </Content>
          )}
        </>
      )}
    </Container>
  );
};

export default EntryReview;

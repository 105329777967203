import { AppSVG } from '../types/OtherTypes';

const NotApplicableSVG = ({ className, onClick, style }: AppSVG) => (
  <svg
    className={className}
    onClick={onClick}
    x="0px"
    y="0px"
    viewBox="-144 -144 288 288"
    style={{
      width: '16px',
      height: '16px',
      ...style
    }}
  >
    <g>
      <path
        d="m-143 0a143 143 0 1 1 286 0 143 143 0 0 1 -286 0m158
           112a113 113 0 0 0 0 -224zm-30-224a113 113 0 0 0 0 224z"
        transform="rotate(45)"
        fill="lightgray"
      />
    </g>
  </svg>
);

export default NotApplicableSVG;

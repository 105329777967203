import styles from './EntryInPendingState.module.css';

const EntryInPendingState = ({ id }: { id: number }) => {
  return (
    <div className={styles.container}>
      <svg className={styles.svg} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <h2>This entry ({id}) is currently being processed. Please wait 15-20 seconds and then refresh the page. </h2>
    </div>
  );
};

export default EntryInPendingState;

const ESLanguageItems = {
  errors: {
    failedEntry: (entry: number) => `⛔ No se pudo recuperar la entrada ${entry} del servidor,`,
    failedEntryAlert: (entry: number) => `Hubo un problema al recuperar la entrada ${entry}, por favor, inténtelo de nuevoe`,
    failedSave: () => 'Hubo un problema al guardar tus cambios, por favor, inténtalo de nuevo',
    noAccess: () => 'No está autorizado para ver esta entrada.'
  },
  strings: {
    addBoxesLabel: () => 'Click on a box to resize or delete it. To add a new box, first select a label and then click and drag on the image.',
    entry: () => 'Entrada',
    tagging: () => 'Etiquetado',
    compare: () => 'Comparar',
    undo: () => 'Deshacer',
    redo: () => 'Rehacer',
    commitChanges: () => 'Enviar Cambios',
    discardChanges: () => 'Desechar los Cambios',
    diffWarning: (added: number, removed: number, modified: number) => `${added} añadido, ${removed} eliminado, and ${modified} modificado`,
    boxesSelected: (boxesCount: number) => `${boxesCount} cajas seleccionadas`,
    filterLabels: () => `Etiquetas de filtro`,
    commitPrompt: () => '¿Estás seguro de que quieres enviar tus cambios?',
    editsSaved: () => '¡Gracias, tus ediciones se han guardado! Ahora puedes pasar a la siguiente entrada.'
  }
};

export default ESLanguageItems;

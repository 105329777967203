import { RefObject, useEffect, useRef } from 'react';
import Konva from 'konva';
import { Rect, Transformer } from 'react-konva';
import { store } from '../../stores/store';
import { useContext } from 'react';
import * as d3 from 'd3-color';
import { Stage } from 'konva/types/Stage';
import { Shape, ShapeConfig } from 'konva/types/Shape';
import { toaster } from '@premisedata/portal-design-system';

export interface CanvasBB {
  bb: BoundingBox;
  target: HTMLDivElement;
  height: number;
  width: number;
  showTooltip: ({ x, y, label }: { x: number; y: number; label: string }) => void;
  hideTooltip: () => void;
}

type ShapeRef = Shape<ShapeConfig>;

const BoundingBox = ({ bb, target, height, showTooltip, hideTooltip }: CanvasBB) => {
  const { state, dispatch } = useContext(store);

  const shapeRef = useRef<Konva.Rect>() as RefObject<Konva.Rect>;
  const trRef = useRef<Konva.Transformer>() as RefObject<Konva.Transformer>;

  const onlySelected = state.selectedBboxes.includes(bb.uuid) && state.selectedBboxes.length <= 1;
  const multiSelected = state.selectedBboxes.includes(bb.uuid) && state.selectedBboxes.length > 1;

  useEffect(() => {
    if (onlySelected) {
      trRef.current?.nodes([shapeRef.current] as ShapeRef[]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [onlySelected]);

  const selectBbox = (bb: BoundingBox) =>
    dispatch({
      type: 'TOGGLE_BB',
      uuid: bb.uuid,
      auditUuid: bb.auditUuid
    });

  const repositionBbox = (x: number, y: number) => {
    dispatch({
      type: 'REPOSITION_BB',
      uuid: bb.uuid,
      auditUuid: bb.auditUuid,
      x: (x / target.clientWidth) * 100,
      y: (y / height) * 100
    });

    if (!state.box_manipulation) {
      toaster.warning("You can't move boxes here.", {});
    }
  };

  const resizeBbox = (node: Shape<ShapeConfig> | Stage) => {
    dispatch({
      type: 'RESIZE_BB',
      uuid: bb.uuid,
      auditUuid: bb.auditUuid,
      width: bb.width * node.scaleX(),
      height: bb.height * node.scaleY(),
      x: (node.x() / target.clientWidth) * 100,
      y: (node.y() / height) * 100
    });
    node.scaleX(1);
    node.scaleY(1);
    if (!state.box_manipulation) {
      toaster.warning("You can't resize boxes here.", {});
    }
  };

  const x = (bb.x * target.clientWidth) / 100;
  const y = (bb.y * height) / 100;
  const widthPx = (bb.width * target.clientWidth) / 100;
  const heightPx = (bb.height * height) / 100;

  return (
    <>
      <Rect
        ref={shapeRef}
        x={x}
        y={y}
        width={widthPx}
        height={heightPx}
        draggable={state.box_manipulation}
        onMouseEnter={() => showTooltip({ x, y, label: bb.fullLabel.name })}
        onMouseOut={() => hideTooltip()}
        stroke={bb.fullLabel.color}
        strokeWidth={multiSelected ? 4 : 2}
        fill={d3
          .rgb(bb.fullLabel.color)
          .copy({ opacity: onlySelected || multiSelected ? 0.8 : 0.5 })
          .toString()}
        dash={multiSelected ? [10, 10] : undefined}
        onClick={() => selectBbox(bb)}
        onDragEnd={(e) => repositionBbox(e.target.x(), e.target.y())}
        onTransformEnd={(e) => {
          resizeBbox(e.target);
        }}
        dragBoundFunc={(pos) => {
          // This function allows to limit dragging selection boxes or bounding boxes outside of the canvas
          return {
            x: pos.x < 0 ? 0 : pos.x > target.clientWidth - widthPx ? target.clientWidth - widthPx : pos.x,
            y: pos.y < 0 ? 0 : pos.y > height - heightPx ? height - heightPx : pos.y
          };
        }}
      />
      {onlySelected && state.box_manipulation && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={false}
          boundBoxFunc={(oldBox, newBox) => {
            // callback called for every bounding box event change cause by the transformer
            const box = newBox;
            // Check for minimum x, y, width and height. Can't be smaller than 20px
            if (box.width < 20) {
              box.width = 20;
              box.x = oldBox.x;
            }
            if (box.height < 20) {
              box.height = 20;
              box.y = oldBox.y;
            }
            //  don't drag bbox outside of the canvas
            if (box.x < 0) {
              box.x = 0;
              box.width = oldBox.width;
            }

            if (box.y < 0) {
              box.y = 0;
              box.height = oldBox.height;
            }

            // don't resize bbox outside of the canvas
            if (box.y + box.height > target.clientHeight) {
              if (box.height <= 20) {
                box.height = 20;
                box.y = target.clientHeight - 20;
              } else {
                box.height = target.clientHeight - box.y;
              }
            }

            if (box.x + box.width > target.clientWidth) {
              if (box.width <= 20) {
                box.width = 20;
                box.x = target.clientWidth - 20;
              } else {
                box.width = target.clientWidth - box.x;
              }
            }

            return box;
          }}
        />
      )}
    </>
  );
};

export default BoundingBox;

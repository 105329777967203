import React, { Dispatch, ReactElement } from 'react';
import { Actions } from '../../stores/reducer';
interface Tabs {
  headers: string[];
  panels?: ReactElement[];
  dispatch: Dispatch<Actions>;
  selectedTab: number;
}
import { Tab, Tabs as PDS_Tabs } from '@premisedata/portal-design-system';

const Tabs = ({ headers, dispatch, selectedTab }: Tabs) => {
  const handleTabChange = ({ activeKey }: { activeKey: React.Key }) => {
    dispatch({
      type: 'CHANGE_MODE',
      mode: activeKey === '0' ? 'tag' : 'compare'
    });
  };

  return (
    <PDS_Tabs activeKey={selectedTab} onChange={handleTabChange}>
      {headers.map((header, index) => (
        <Tab key={index} title={header} />
      ))}
    </PDS_Tabs>
  );
};

export default Tabs;

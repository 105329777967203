import { useContext, useState, useEffect } from 'react';
import { store } from '../../stores/store';
import ImageAudit from '../ImageAudit';
import { isAudit } from '../../types/TypeGuards';
import { Block, styled, tokens, useStyletron } from '@premisedata/portal-design-system';

const AuditContainer = styled('div', ({ $theme }) => ({
  flex: 1,
  background: $theme.colors['neutral-25'],
  borderRadius: tokens['corner-radius']['semi-sharp']
}));

const AuditWarning = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors['brand-primary-150'],
  margin: `${$theme.sizing['small']} 0`,
  padding: $theme.sizing['xx-small']
}));

const AuditSelector = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: $theme.sizing.small
}));

const AuditIndicator = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors['neutral-0'],
  height: $theme.sizing.large,
  width: $theme.sizing.large,
  borderRadius: '13px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s'
}));

const Compare = () => {
  const {
    state: { content },
    dispatch
  } = useContext(store);
  const [, theme] = useStyletron();

  const [auditA, setAuditA] = useState(0);
  const [auditB, setAuditB] = useState(1);

  // Delete with Backspace
  useEffect(() => {
    const backspaceDelete = (e: KeyboardEvent) => {
      if (e.key === 'Backspace') {
        dispatch({ type: 'DELETE_SELECTED' });
      }
    };

    window.addEventListener('keydown', backspaceDelete);
    return () => {
      window.removeEventListener('keydown', backspaceDelete);
    };
  }, []);

  const audits = content.filter(isAudit);

  return (
    <Block display="flex" flexDirection="column" flex="1">
      {auditA === auditB && <AuditWarning>⚠️ Displaying same images</AuditWarning>}
      <Block display="flex" gridColumnGap={theme.sizing.small} marginTop={theme.sizing.small}>
        <AuditContainer>
          <AuditSelector>
            {audits.map((_, index) => (
              <AuditIndicator
                key={index}
                onClick={() => setAuditA(index)}
                style={{
                  border: `2px solid ${theme.colors[index === auditA ? 'brand-primary' : 'neutral-75']}`
                }}
              >
                {index + 1}
              </AuditIndicator>
            ))}
          </AuditSelector>
          <ImageAudit {...audits[auditA]} hideLabels={false} hideBoundingBoxes={false} />
        </AuditContainer>
        <AuditContainer>
          <AuditSelector>
            {audits.map((_, index) => (
              <AuditIndicator
                key={index}
                onClick={() => setAuditB(index)}
                style={{
                  border: `2px solid ${theme.colors[index === auditB ? 'brand-primary' : 'neutral-75']}`
                }}
              >
                {index + 1}
              </AuditIndicator>
            ))}
          </AuditSelector>
          <ImageAudit {...audits[auditB]} hideLabels={false} hideBoundingBoxes={false} />
        </AuditContainer>
      </Block>
    </Block>
  );
};

export default Compare;

import { useContext } from 'react';
import LanguagePack from '../../res';
import { store } from '../../stores/store';
import { Button, BUTTON_KIND, Label, styled, useStyletron, tokens } from '@premisedata/portal-design-system';

const Container = styled('div', ({ $theme }) => ({
  alignItems: 'center',
  backgroundColor: $theme.colors['brand-secondary-1-25'],
  columnGap: $theme.sizing.small,
  display: 'flex',
  padding: $theme.sizing['xx-small'],
  margin: `0 ${$theme.sizing.small}`,
  borderRadius: tokens['corner-radius']['semi-sharp']
}));

// Shows a warning if the user current state is different from the state on the server
const DiffWarning = ({ id, query_category_id }: { id: string; query_category_id: number }) => {
  const { state, dispatch } = useContext(store);
  const [, theme] = useStyletron();
  const lang = LanguagePack(state.defaultLanguage);

  return state.dirtyCounts.added > 0 || state.dirtyCounts.modified > 0 || state.dirtyCounts.removed > 0 ? (
    <Container>
      <Label color={theme.colors['neutral-0']}>{lang.strings.diffWarning(state.dirtyCounts.added, state.dirtyCounts.removed, state.dirtyCounts.modified)}</Label>
      <Button
        kind={BUTTON_KIND.secondary}
        onClick={() => {
          const key = `${id}-${query_category_id}-last-edit`;
          localStorage.removeItem(key);
          dispatch({ type: 'DISCARD_CHANGES' });
        }}
      >
        {lang.strings.discardChanges()}
      </Button>
    </Container>
  ) : null;
};

export default DiffWarning;

import ENLanguageItems from './en';
import ESLanguageItems from './es';
import PTLanguageItems from './pt';

const LanguagePack = (language: string) => {
  if (process.browser && language.includes('es')) {
    return ESLanguageItems;
  } else if (process.browser && language.includes('pt')) {
    return PTLanguageItems;
  } else {
    return ENLanguageItems;
  }
};

export type LanguagePackType = typeof ENLanguageItems;

export default LanguagePack;

import { useContext } from 'react';
import LanguagePack from '../../res';
import { store } from '../../stores/store';
import styles from './Completed.module.css';

const Completed = () => {
  const { state } = useContext(store);
  const lang = LanguagePack(state.defaultLanguage);
  return (
    <div className={styles.completedIconContainer}>
      <div>{lang.strings.editsSaved()}</div>
      <svg className={styles.completedIcon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
  );
};

export default Completed;

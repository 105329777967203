const ESLanguageItems = {
  errors: {
    failedEntry: (entry: number) => `⛔ Não foi conseguido obter entrada ${entry} do servidor`,
    failedEntryAlert: (entry: number) => `Houve um problema recuperando a entrada ${entry}, por favor tente novamente`,
    failedSave: () => 'Houve um problema em salvar suas mudanças, por favor, tente novamente.',
    noAccess: () => 'Você não está autorizado a ver esta entrada.'
  },
  strings: {
    addBoxesLabel: () => 'Click on a box to resize or delete it. To add a new box, first select a label and then click and drag on the image.',
    entry: () => 'Entrada',
    tagging: () => 'Tagging',
    compare: () => 'Compare',
    undo: () => 'Desfazer',
    redo: () => 'Refazer',
    commitChanges: () => 'Cometer mudanças',
    discardChanges: () => 'Mudanças de descarte',
    diffWarning: (added: number, removed: number, modified: number) => `${added} adicionado, ${removed} removido, and ${modified} modificado`,
    boxesSelected: (boxesCount: number) => `${boxesCount} caixas selecionadas`,
    filterLabels: () => `Etiquetas de filtro`,
    commitPrompt: () => 'Tem certeza que quer cometer suas mudanças?',
    editsSaved: () => 'Obrigado, suas mudanças foram salvas! Agora você pode passar para a próxima entrada'
  }
};

export default ESLanguageItems;

import React, { useContext } from 'react';

import { padding } from 'polished';

import { Button, BUTTON_KIND, BUTTON_SIZE, LabelSmall, styled, StyledLinkLabelSmall, tokens } from '@premisedata/portal-design-system';

const Container = styled('div', ({ $theme }) => ({
  ...padding($theme.sizing['xx-small']),
  backgroundColor: $theme.colors['neutral-0'],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  rowGap: $theme.sizing['xx-small']
}));

const StyledRow = styled('div', ({ $theme }) => ({
  columnGap: $theme.sizing.large,
  display: 'flex'
}));

function Footer(): JSX.Element {
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <StyledRow>
        <LabelSmall color={tokens.color['neutral-75']} data-qa-id="footerCopyright">
          &copy; {currentYear} Premise
        </LabelSmall>
        <StyledLinkLabelSmall data-qa-id="footerTermsOfService" href="https://www.premise.com/terms-of-service/" rel="noopener noreferrer" target="_blank">
          Terms of service
        </StyledLinkLabelSmall>
        <StyledLinkLabelSmall data-qa-id="footerPrivacyPolicy" href="https://www.premise.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
          Privacy policy
        </StyledLinkLabelSmall>
      </StyledRow>
    </Container>
  );
}

export default Footer;

import { NextRouter } from 'next/router';
import { Dispatch } from 'react';
import { Actions } from '../stores/reducer';
import { isAudit } from '../types/TypeGuards';
import LanguagePack from '../res';
import qs from 'qs';
import { toaster } from '@premisedata/portal-design-system';
import { AppState } from '../types/AppState';

export const http = async <T>(url: string, params?: RequestInit): Promise<T> => {
  const response = await fetch(url, params);
  if (response.ok) {
    const json = await response.json();

    if (json.Error) {
      throw new Error(json.Error);
    }

    return json;
  } else {
    const errorMessage = await response.text();
    throw new Error(errorMessage || `The call to ${url} failed with code: ${response.status}`);
  }
};

export const FetchEntry = async (state: AppState, query: qs.ParsedQs, token: string): Promise<EntryWithCategory> => {
  const query_category_id = query['category_id'] ? Number(query['category_id']) : Number.NaN;

  const params = query.label_set_id ? `submission_id=${query.id}&label_set_id=${query.label_set_id}` : `submission_id=${query.id}`;

  let url = process.env.NEXT_PUBLIC_MOCK_BACKEND === 'true' ? 'http://localhost:8081/mockResponse.json' : `${process.env.NEXT_PUBLIC_LABELER_ENDPOINT}/labeling-metadata?${params}`;

  const res = await http<EntryFromServer>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return {
    ...res,
    query_category_id: query_category_id
  };
};

export const SaveEntry = async (state: AppState, router: NextRouter, dispatch: Dispatch<Actions>, token: string): Promise<void> => {
  const lang = LanguagePack(state.defaultLanguage);
  const url = `${process.env.NEXT_PUBLIC_LABELER_ENDPOINT}/labeling-metadata`;
  const { query } = router;
  const payload = {
    id: state.id,
    ...query,
    audits: state.content.filter(isAudit).map((item) => {
      return {
        id: item.id,
        bboxes: item.bboxes.map((bb) => {
          const { width, height, x, y, label_id, uuid } = bb;
          return { width, height, x, y, label_id, uuid };
        })
      };
    }),
    category_id: state.category_id,
    startTime: state.startTime,
    endTime: Date.now(),
    apiKey: state.query.apiKey ? state.query.apiKey : null
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (response.ok) {
      dispatch({ type: 'SAVE_COMPLETED' });
    } else {
      toaster.negative(await response.text(), {});
    }
  } catch (err: any) {
    toaster.negative(lang.errors.failedSave(), {});
    await state.errorHandler.report(err);
  }
};

import { KonvaEventObject } from 'konva/types/Node';
import { Stage } from 'konva/types/Stage';
import { Vector2d } from 'konva/types/types';
import { Dispatch, SetStateAction } from 'react';
import { Actions } from '../../stores/reducer';
import { toaster } from '@premisedata/portal-design-system';
import { AppState } from '../../types/AppState';

interface HistoryStep {
  ts: number;
  type: string;
  keysState?: any;
  state: any;
}
let history = [] as HistoryStep[];

export const checkDeselect = (e: KonvaEventObject<MouseEvent>, dispatch: Dispatch<Actions>) => {
  const clickedOnEmpty = e.target.className !== 'Rect';
  if (clickedOnEmpty) {
    dispatch({
      type: 'DESELECT_ALL'
    });
  }
};

// handle zooming into and out of the canvas, with mouse scroll wheel
export const zoom = (e: KonvaEventObject<WheelEvent>, keysState: KeyState, setStageScale: Dispatch<SetStateAction<StageState>>, stageState: StageState) => {
  if (keysState.shift) {
    e.evt.preventDefault();
    const scaleBy = 0.05; // how quickly to scale by
    const stage = e.target.getStage() as Stage;
    const oldScale = stage.scaleX();
    const pointerPosition = stage.getPointerPosition() as Vector2d;
    const newScale = e.evt.deltaY > 0 ? oldScale + scaleBy : oldScale - scaleBy;

    const xDiff = (pointerPosition.x - stageState.x) * (newScale / oldScale - 1);
    const yDiff = (pointerPosition.y - stageState.y) * (newScale / oldScale - 1);

    setStageScale({
      ...stageState,
      scale: newScale,
      x: Math.round(stageState.x - xDiff),
      y: Math.round(stageState.y - yDiff)
    });
  }
};

// just reset dragging state and set the origin to the current mouse position
export const startDragging = (e: KonvaEventObject<MouseEvent>, setDragState: Dispatch<SetStateAction<DragState>>, initialDragState: DragState, stageState: StageState) => {
  if (e.target.className !== 'Rect') {
    const stage = e.target.getStage() as Stage;
    const stagePosition = stage.position();
    let { x, y } = stage.getPointerPosition() as Vector2d;
    const boxX = (x - stagePosition.x) / stageState.scale;
    const boxY = (y - stagePosition.y) / stageState.scale;
    const newDragState = {
      ...initialDragState,
      dragging: true,
      originX: x,
      originY: y,
      boxX,
      boxY,
      boxH: 0,
      boxW: 0
    };
    setDragState(newDragState);
    history.push({
      type: 'start-dragging',
      state: newDragState,
      ts: Date.now()
    });
  }
};

// render the updated position and size of the box as the user drags
export const drag = (
  e: KonvaEventObject<MouseEvent>,
  dragState: DragState,
  setDragState: Dispatch<SetStateAction<DragState>>,
  stageState: StageState,
  keysState: KeyState,
  setStageState: Dispatch<SetStateAction<StageState>>
) => {
  if (dragState.dragging) {
    console.log('Started dragging', dragState);
    const stage = e.target.getStage() as Stage;
    const stagePosition = stage.position();
    let { x, y } = stage.getPointerPosition() as Vector2d;
    const adjustedX = (x - stagePosition.x) / stageState.scale;
    const adjustedY = (y - stagePosition.y) / stageState.scale;
    const newSelectState = {
      boxX: Math.min(adjustedX, dragState.originX),
      boxY: Math.min(adjustedY, dragState.originY),
      boxH: Math.abs(adjustedY - dragState.originY),
      boxW: Math.abs(adjustedX - dragState.originX),
      endX: x,
      endY: y
    };

    setDragState({ ...dragState, ...newSelectState });

    if (keysState.shift) {
      setStageState({
        ...stageState,
        panX: dragState.originX - x,
        panY: dragState.originY - y
      });
    }
    history.push({
      type: 'dragging',
      keysState,
      state: { ...dragState, ...newSelectState },
      ts: Date.now()
    });
  }
};

// stop dragging, reset the drag state and dispatch the relevant action
export const finishDragging = (
  dragState: DragState,
  keysState: KeyState,
  state: AppState,
  dispatch: Dispatch<Actions>,
  target: HTMLDivElement,
  initialDragState: DragState,
  setDragState: Dispatch<SetStateAction<DragState>>,
  id: number,
  height: number,
  setStageState: Dispatch<SetStateAction<StageState>>,
  stageState: StageState
) => {
  console.log('Finished dragging', dragState);
  if (dragState.dragging) {
    console.log('Dragging', dragState);
    const { boxX, boxY, boxH, boxW } = dragState;
    const x = (boxX / target.clientWidth) * 100;
    const y = (boxY / height) * 100;
    const w = (boxW / target.clientWidth) * 100;
    const h = (boxH / height) * 100;
    const coordinates = {
      x,
      y,
      w,
      h
    };
    // control key is down, so we're adding are selecting all the boxes intersecting the new box
    keysState.ctrl &&
      dispatch({
        type: 'GROUP_SELECT',
        coordinates,
        audit: id
      });

    // no mod keys are down, so we're adding a new box and ensuring is at least 10px wide
    // (should never really be less than 20 but we had a lot of issues with this in the past. just an extra safety check)
    if (!keysState.ctrl && !keysState.shift && state.selectedLabel && boxW > 10 && boxH > 10) {
      if (state.box_manipulation) {
        dispatch({
          type: 'CREATE_BOUNDING_BOX',
          coordinates,
          audit: id
        });
      } else {
        toaster.warning("You can't create boxes here.", {});
      }

      history.push({
        type: 'end-dragging',
        keysState,
        state: dragState,
        ts: Date.now()
      });

      // Same as above extra safety check and finally ping to google the whole dragging history for debugging
      if (coordinates.w < 1 || coordinates.h < 1) {
        state.errorHandler.report({
          name: 'Box error',
          message: 'Extremely small box created',
          stack: JSON.stringify(history.sort((a, b) => a.ts - b.ts))
        });
      }
    }

    // reset dragging state
    history = [];
    setDragState(initialDragState);
    setStageState({
      ...stageState,
      x: stageState.x - stageState.panX,
      y: stageState.y - stageState.panY,
      panX: 0,
      panY: 0
    });
  }
};

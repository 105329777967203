import { store } from '../../stores/store';
import { Dispatch, useContext } from 'react';
import Label from '../Label';
import { Actions } from '../../stores/reducer';
import { isAudit } from '../../types/TypeGuards';
import LanguagePack, { LanguagePackType } from '../../res';
import {
  Block,
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  FontWeight,
  InfoOutlineIcon,
  Input,
  Label as PdsLabel,
  LabelSmall,
  StatefulTooltip,
  styled,
  Tag,
  tokens,
  useStyletron
} from '@premisedata/portal-design-system';

interface SelectedIndicator {
  selected: string[];
  box_manipulation: boolean;
  dispatch: Dispatch<Actions>;
  lang: LanguagePackType;
}

const AddBoxesContainer = styled('div', ({ $theme }) => ({
  background: $theme.colors['brand-secondary-1-10'],
  border: `2px solid ${$theme.colors['brand-secondary-1-100']}`,
  textAlign: 'center',
  padding: $theme.sizing.small,
  marginBottom: $theme.sizing.small,
  color: $theme.colors['brand-secondary-1-100']
}));

const Container = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors['neutral-10'],
  borderTop: `solid 1px ${$theme.colors['neutral-25']}`,
  width: tokens.width['right-drawer-expanded'],
  paddingTop: $theme.sizing['xx-small'],
  paddingLeft: $theme.sizing['xx-small'],
  paddingRight: $theme.sizing['xx-small'],
  marginRight: $theme.sizing['small'],
  display: 'flex',
  flexDirection: 'column',
  rowGap: $theme.sizing.small,
  overflowY: 'auto'
}));

const HotkeyRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  columnGap: $theme.sizing.small,
  alignItems: 'center'
}));

const HotkeyInfo = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: $theme.colors['brand-primary'],
  columnGap: $theme.sizing['xxx-small'],
  cursor: 'pointer'
}));

const LabelContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: $theme.sizing['xx-small'],
  paddingBottom: $theme.sizing['xx-small']
}));

const SelectedIndicator = ({ selected, box_manipulation, dispatch, lang }: SelectedIndicator) => {
  const [, theme] = useStyletron();

  return (
    <>
      <div>
        {box_manipulation && <AddBoxesContainer>{lang.strings.addBoxesLabel()}</AddBoxesContainer>}
        <StatefulTooltip
          accessibilityType="tooltip"
          content={
            <Block display="flex" flexDirection="column" gridRowGap={theme.sizing['xx-small']}>
              <HotkeyRow>
                <Tag>shift + drag</Tag>
                <LabelSmall color={theme.colors['neutral-75']}>Move the image</LabelSmall>
              </HotkeyRow>
              <HotkeyRow>
                <Tag>shift + scroll</Tag>
                <LabelSmall>Zoom in / out</LabelSmall>
              </HotkeyRow>
              <HotkeyRow>
                <Tag>ctrl + drag</Tag>
                <LabelSmall>Select Multiple</LabelSmall>
              </HotkeyRow>
            </Block>
          }
        >
          <HotkeyInfo>
            Hotkey Reference
            <InfoOutlineIcon color={theme.colors['brand-primary']} size={tokens['icon-scale'].small} />
          </HotkeyInfo>
        </StatefulTooltip>
      </div>
      <Block display="flex" marginTop={theme.sizing['x-small']} alignItems="center" gridColumnGap={theme.sizing.small}>
        <PdsLabel fontWeight={selected.length > 0 ? FontWeight.Bold : undefined} color={theme.colors['neutral-75']}>
          {lang.strings.boxesSelected(selected.length)}
        </PdsLabel>
        {selected.length > 0 && (
          <>
            <Button
              overrides={{ Root: { props: { id: 'sidebar-deselect-all' } } }}
              kind={BUTTON_KIND.secondary}
              size={BUTTON_SIZE.mini}
              onClick={() => dispatch({ type: 'DESELECT_ALL' })}
            >
              Clear Selection
            </Button>
            {box_manipulation && (
              <Button overrides={{ Root: { props: { id: 'sidebar-delete-selected' } } }} size={BUTTON_SIZE.mini} onClick={() => dispatch({ type: 'DELETE_SELECTED' })}>
                Delete Selected
              </Button>
            )}
          </>
        )}
      </Block>
    </>
  );
};

interface AuditCount {
  [id: number]: number;
}

const Sidebar = () => {
  const { state, dispatch } = useContext(store);
  const lang = LanguagePack(state.defaultLanguage);
  const counts = state.content.reduce((all, item: Media) => {
    if (isAudit(item)) {
      item.bboxes.map((bb) => {
        if (Object.keys(all).includes(String(bb.label_id))) {
          all[bb.label_id] += 1;
        } else {
          all[bb.label_id] = 1;
        }
      });
    }
    return all;
  }, {} as AuditCount);

  if (state.labeling === false) return null;
  return (
    <Container>
      {state.notice && <p>{state.notice}</p>}
      <SelectedIndicator selected={state.selectedBboxes} box_manipulation={state.box_manipulation} dispatch={dispatch} lang={lang} />
      <Block>
        <Input
          overrides={{ Input: { props: { id: 'sidebar-label-filter' } } }}
          placeholder={lang.strings.filterLabels()}
          type="text"
          onChange={(e) => {
            const tar = e.target as HTMLInputElement;
            dispatch({
              type: 'SET_LABEL_FILTER',
              filter: tar.value.toLowerCase()
            });
          }}
        />
      </Block>
      <LabelContainer>
        {state &&
          state.labels
            .filter((l) => (state.labelFilter ? l.name.toLowerCase().includes(state.labelFilter) : l))
            .sort((l1, l2) => l1.name.localeCompare(l2.name))
            .map((label, key) => <Label key={key} label={label} quantity={counts[label.id]} />)}
      </LabelContainer>
    </Container>
  );
};
export default Sidebar;

import { useContext } from 'react';
import { store } from '../../stores/store';
import NotApplicableSVG from '../../svgs/NotApplicable';
import {
  Block,
  BoundaryL0FillIcon,
  Card,
  FontWeight,
  HideOutlineIcon,
  IconButton,
  LabelSmall,
  ShowOutlineIcon,
  styled,
  tokens,
  useStyletron
} from '@premisedata/portal-design-system';

const StyledCard = styled(Card, ({ $theme }) => ({
  alignItems: 'center',
  backgroundColor: $theme.colors['neutral-10'],
  display: 'flex',
  columnGap: $theme.sizing['xx-small'],
  padding: $theme.sizing['xx-small']
}));

interface LabelComp {
  label: Label;
  quantity: number;
}

const Label = ({ label, quantity }: LabelComp) => {
  const { state, dispatch } = useContext(store);
  const [, theme] = useStyletron();
  const visible = state.visibleLabels.length === 0 || state.visibleLabels.includes(label.id);
  return (
    <StyledCard
      clickable
      selected={state.selectedLabel === label}
      onClick={() => {
        if (state.selectedBboxes.length > 0) {
          dispatch({ type: 'UPDATE_LABEL_FOR_SELECTED', label });
        }
        dispatch({ type: 'TOGGLE_SELECTED_LABEL', label });
      }}
    >
      <IconButton
        icon={visible ? ShowOutlineIcon : HideOutlineIcon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch({ type: 'TOGGLE_LABEL_VISIBILITY', label });
        }}
      />
      <BoundaryL0FillIcon color={label.color} size={tokens['icon-scale'].large} />
      {label.image ? (
        <div
          onAuxClick={() => label.image && window.open(label.image)}
          style={{
            width: 64,
            height: 64,
            marginRight: '.5em',
            borderRadius: '5px',
            background: label.image ? `white url("${label.image}") no-repeat scroll center / contain` : 'transparent'
          }}
        />
      ) : (
        <NotApplicableSVG style={{ width: '64px', height: '32px' }} />
      )}
      <div style={{ flex: 1 }}>
        <LabelSmall color={theme.colors['neutral-75']} fontWeight={FontWeight.Bold}>
          {label.name}
        </LabelSmall>
        {label.description && <LabelSmall color={theme.colors['neutral-75']}>{label.description}</LabelSmall>}
      </div>
      <Block paddingRight={theme.sizing['xx-small']}>
        <LabelSmall>{quantity || 0}</LabelSmall>
      </Block>
    </StyledCard>
  );
};

export default Label;

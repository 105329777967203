import { useRouter } from 'next/router';
import Tabs from '../Tabs';
import { useContext } from 'react';
import { store } from '../../stores/store';
import { SaveEntry } from '../../endpoints';
import LanguagePack from '../../res';
import {
  Block,
  Button,
  BUTTON_KIND,
  BUTTON_SIZE,
  FontWeight,
  Label,
  LanguageOutlineIcon,
  POPOVER_PLACEMENT,
  PopoverMenu,
  PremiseLogo,
  ProfileOutlineIcon,
  StyledListItem,
  styled,
  toaster,
  tokens,
  useStyletron,
  StatefulPopover,
  LabelSmall,
  withStyle,
  IconButton
} from '@premisedata/portal-design-system';
import { useFirebaseAuth } from '../../providers/firebaseContext';
import { padding } from 'polished';
import Footer from '../Footer/footer';

interface Navbar {
  hasError: boolean;
  hideLabels: boolean;
  hideBoundingBoxes: boolean;
  onSignout: () => void;
}

const MenuSection = styled('div', ({ $theme }) => ({
  background: $theme.colors['neutral-0'],
  borderBottom: `1px solid ${$theme.colors['neutral-25']}`,
  ...padding($theme.sizing['x-small'])
}));

const PaddedListItem = withStyle(StyledListItem, ({ $theme }) => ({
  ...padding($theme.sizing['x-small'])
}));

const TopBar = styled('div', ({ $theme }) => ({
  display: 'flex',
  padding: `${$theme.sizing['xxx-small']} ${$theme.sizing['small']}`,
  columnGap: $theme.sizing.small,
  alignItems: 'center',
  boxShadow: tokens['box-shadow'].card
}));

const NavBar = ({ hasError, hideLabels, hideBoundingBoxes, onSignout }: Navbar) => {
  const { user } = useFirebaseAuth();
  const router = useRouter();
  const { query } = router;

  const [, theme] = useStyletron();

  const { state, dispatch } = useContext(store);
  const lang = LanguagePack(state.defaultLanguage);

  return (
    <TopBar>
      <PremiseLogo active />
      <PopoverMenu
        placement={POPOVER_PLACEMENT.bottomRight}
        items={['en', 'es', 'pt'].map((lang) => ({
          label: lang.toUpperCase()
        }))}
        onItemSelect={(item) => dispatch({ type: 'CHANGE_LANGUAGE', lang: item.label.toLowerCase() })}
      >
        <Block paddingTop={theme.sizing['xx-small']}>
          <LanguageOutlineIcon onClick={() => null} color={theme.colors['neutral-50']} />
        </Block>
      </PopoverMenu>
      <Block>
        <Tabs headers={[lang.strings.tagging(), lang.strings.compare()]} dispatch={dispatch} selectedTab={state.mode === 'tag' ? 0 : 1} />
      </Block>
      <Block flex="1">
        <Label color={theme.colors['brand-primary']} fontWeight={FontWeight.Bold}>
          {lang.strings.entry()} {query.id}
        </Label>
      </Block>
      <Block display="flex" gridColumnGap={theme.sizing['xx-small']}>
        <Button disabled={hasError} onClick={() => dispatch({ type: 'UNDO' })} kind={BUTTON_KIND.secondary}>
          {lang.strings.undo()}
        </Button>
        <Button disabled={hasError} onClick={() => dispatch({ type: 'REDO' })} kind={BUTTON_KIND.secondary}>
          {lang.strings.redo()}
        </Button>
        <Button
          disabled={hasError}
          onClick={async () => {
            const unlabeledLabelId = state.labels.find((label) => label.name === 'Unlabeled')?.id;

            if (
              state.content.find((image) => {
                return image.bboxes.find((bbox) => {
                  return !bbox.label_id || bbox.label_id === unlabeledLabelId;
                });
              })
            ) {
              toaster.negative('You must label all boxes in order to commit changes.', {});
              return;
            }

            if (!hideLabels && !hideBoundingBoxes) {
              const confirmation = confirm(lang.strings.commitPrompt());
              if (confirmation) {
                const token = await user?.getIdToken();
                SaveEntry(state, router, dispatch, token || '');
              }
            }
          }}
        >
          {lang.strings.commitChanges()}
        </Button>

        <StatefulPopover
          content={
            <>
              <MenuSection>
                <Label>{user?.displayName}</Label>
                <LabelSmall color={theme.colors['neutral-50']}>{user?.email}</LabelSmall>
              </MenuSection>
              <div style={{ borderBottom: `1px solid ${theme.colors['neutral-25']}` }}>
                <PaddedListItem onClick={onSignout}>Sign out</PaddedListItem>
              </div>
              <Footer />
            </>
          }
          placement={POPOVER_PLACEMENT.bottomRight}
        >
          <IconButton icon={ProfileOutlineIcon} />
        </StatefulPopover>
      </Block>
    </TopBar>
  );
};

export default NavBar;

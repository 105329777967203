const ENLanguageItems = {
  errors: {
    failedEntry: (entry: number) => `⛔ Couldn't get entry ${entry} from the server`,
    failedEntryAlert: (entry: number) => `There was an issue retrieving entry ${entry}, please try again`,
    failedSave: () => 'There was an issue saving your changes, please try again.',
    noAccess: () => 'You are not authorised to view this entry.'
  },
  strings: {
    addBoxesLabel: () => 'Click on a box to resize or delete it. To add a new box, first select a label and then click and drag on the image.',
    entry: () => 'Entry',
    tagging: () => 'Tagging',
    compare: () => 'Compare',
    undo: () => 'Undo',
    redo: () => 'Redo',
    commitChanges: () => 'Commit Changes',
    discardChanges: () => 'Discard Changes',
    diffWarning: (added: number, removed: number, modified: number) => `${added} added, ${removed} removed, and ${modified} modified`,
    boxesSelected: (boxesCount: number) => `${boxesCount} selected`,
    filterLabels: () => `Filter Labels`,
    commitPrompt: () => 'Are you sure you want to commit your changes?',
    editsSaved: () => 'Thanks, your edits have been saved! You can now move onto the next entry.'
  }
};

export default ENLanguageItems;
